<template>
  <div class="valuation-form-container">
    <form id="property-valuation-form"
          @submit.prevent="submitForm"
          method="post"
    >
      <div :class="{'form-group': true,'row': !isVert }">

        <label for="name" class="col-sm-4 col-form-label">Name:</label>
        <div :class="{'col-sm-8':!isVert,'col-sm-12': isVert}">
          <input class="form-control" id="name" v-model="$v.field_name.$model" type="text"
                 placeholder="First and Last Name" />
          <div class="form-text text-danger" v-if="$v.field_name.$anyError">Please ensure both your First and Last name
            are present
          </div>
        </div>
      </div>
      <div :class="{'form-group': true,'row': !isVert }">
        <label for="st" :class="{'col-sm-4': !isVert, 'col-sm-12': isVert,  'col-form-label': true}">Street Address:</label>
        <div :class="{'col-sm-8':!isVert,'col-sm-12': isVert}">
          <input class="form-control" id="st" v-model="$v.field_address1.$model" type="text"
                 placeholder="Street Address" />
          <div class="form-text text-danger" v-if="$v.field_address1.$anyError">Invalid address</div>
        </div>
      </div>
      <div :class="{'form-group': true,'row': !isVert }">
        <label for="st" :class="{'col-sm-4': !isVert, 'col-sm-12': isVert,  'col-form-label': true}">Street Address2:</label>
        <div :class="{'col-sm-8':!isVert,'col-sm-12': isVert}">

          <input class="form-control" v-model="$v.field_address2.$model" type="text"
                 placeholder="Street Address 2" />
        </div>
      </div>

      <div :class="{'form-group': true,'row': !isVert }">
        <label for="st" class="col-sm-4 col-form-label">City:</label>
        <div :class="{'col-sm-8':!isVert,'col-sm-12': isVert}">

          <input class="form-control" v-model="$v.field_city.$model" type="text" placeholder="City" />
          <div class="form-text text-danger" v-if="$v.field_city.$anyError">Invalid city name</div>
        </div>
      </div>


      <div :class="{'form-group': true,'row': !isVert }">
        <label for="st" class="col-sm-4 col-form-label">State:</label>
        <div :class="{'col-sm-3':!isVert,'col-sm-12': isVert}">
          <input class="form-control" v-model="$v.field_state.$model" type="text" placeholder="State" />
          <div class="form-text text-danger" v-if="$v.field_state.$anyError">Invalid State Code (ST)</div>
        </div>
        <label v-if="!isVert" for="st" class="col-sm-2 pl-0 pr-0 col-form-label">Zip Code:</label>

        <div v-if="!isVert" :class="{'col-sm-3': !isVert, 'col-sm-12':isVert}">
          <input class="form-control" v-model="$v.field_zip.$model" type="text" placeholder="Zip Code" />
          <div class="form-text text-danger" v-if="$v.field_zip.$anyError">Invalid Zip Code</div>
        </div>

      </div>

      <div  v-if="isVert" :class="{'form-group': true,'row': !isVert }">
        <label for="st" class="col-sm-4 col-form-label">Zip Code:</label>

        <div :class="{'col-sm-3':!isVert,'col-sm-12': isVert}">
          <input class="form-control" v-model="$v.field_zip.$model" type="text" placeholder="Zip Code" />
          <div class="form-text text-danger" v-if="$v.field_zip.$anyError">Invalid Zip Code</div>
        </div>

      </div>

      <div :class="{'form-group': true,'row': !isVert }">
        <label class="col-sm-4 col-form-label">Phone:</label>
        <div :class="{'col-sm-8':!isVert,'col-sm-12': isVert}">
          <input class="form-control" v-model="$v.field_phone.$model" type="text" v-on:blur="formatPhone"
                 placeholder="Phone" />
          <div class="form-text text-danger" v-if="$v.field_phone.$anyError">Please enter a valid phone number: (123)
            456-7890
          </div>
        </div>
      </div>
      <div :class="{'form-group': true,'row': !isVert }">
        <label class="col-sm-4 col-form-label">Email:</label>
        <div :class="{'col-sm-8':!isVert,'col-sm-12': isVert}">
          <input class="form-control" v-model="$v.field_email.$model" type="email" placeholder="Email" />
          <div class="form-text text-danger" v-if="$v.field_email.$anyError">Please enter a valid email address</div>
        </div>
      </div>
      <div v-if="!noSubmitBtn" :class="{'form-group': true,'row': !isVert }">
        <div class="col-sm-12 text-right">
          <button type="submit" class="btn btn-dark" :disabled="loading">Continue</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email, helpers, minLength } from 'vuelidate/lib/validators'

const field_name_regex = helpers.regex('name_regex', /\w{2,}\s\w{2,}/i);
const field_state_regex = helpers.regex('state_regex', /^[A-Z]{2}$/i);
const field_zip_regex = helpers.regex('zip_regex', /^\d{5}(-?\d{4})?$/i);
const field_phone_regex = helpers.regex('phone_regex', /^\(?[\d]{3}\)?[\s-]?[\d]{3}[\s-]?[\d]{4}$/);

export default {
  name: 'ValuationForm',
  props: {
    layout: String,
    agentId: String,
    onSubmit: Function,
    loading: Boolean,
    noSubmitBtn: {type: Boolean, default: false},
  },
  data: function () {
    return {
      field_name: '',
      field_address1: '',
      field_address2: '',
      field_city: '',
      field_state: '',
      field_zip: '',
      field_phone: '',
      field_email: '',
      loader: false,
    };
  },
  validations: {
    field_name: {
      required,
      field_name_regex,
    },
    field_address1: {
      required,
      minLength: minLength(2),
    },
    field_address2: {},
    field_city: {
      required,
      minLength: minLength(2),
    },
    field_state: {
      required,
      field_state_regex,
    },
    field_zip: {
      required,
      field_zip_regex,
    },
    field_phone: {
      required,
      field_phone_regex,
    },
    field_email: {
      required,
      email,
    },
  },
  methods: {
    submitForm() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const [ownerFirstname, ownerLastname] = this.field_name.split(' ');
        const contact =
            {
              'city': this.field_city,
              'geocode': {
                'lat': 0,
                'lng': 0,
              },
              'owner_firstname': ownerFirstname,
              'owner_lastname': ownerLastname,
              'state': this.field_state,
              'street_address_1': this.field_address1,
              'street_address_2': this.field_address2,
              'zip': this.field_zip,
              'email': this.field_email,
            }

        this.$emit('on-submit', contact);
      }
      // console.debug('Valuation Form Submit');
      //TODO: Implement Lead submission here
    },
    formatPhone(e) {
      let phone = e.target.value;
      phone = phone.replace(/\D/g, '');

      if (/\d{9,}/.test(phone)) {
        e.target.value = phone.replace(/^(\d{3})(\d{3})(\d{4})(\d+)?/, (total, areaCode, prefix, number, extension) => {
          let formattedNumber = `(${areaCode})`;
          if (prefix) formattedNumber += ` ${prefix}`;
          if (number) formattedNumber += `-${number}`;
          if (extension) formattedNumber += ` x${extension}`;
          return formattedNumber;
        });
      }
    },
  },
  watch: {},
  computed: {
    isVert: function () {
      return this.layout === 'vertical';
    },
  },

}
</script>

<style scoped>
.w100 {
  width: 100%;
}

.btn-highlight {
  background-color: #ff8e00;
  border: 1px solid #ff8e00;
  text-transform: uppercase;
  color: #fff;
}

.btn {
  font-size: 16px !important;
}
</style>