<template>
  <div class="agent-info-container">
    <div class="flex-container flex-container-0">
      <div v-if="agent.image" class="">
        <img :src="agent.image" alt="Agent Photo" />
      </div>

      <div class="flex-container box p-l-2">
        <h6
          v-if="agent.FirstName || agent.LastName"
          class="text-left agent-name"
        >
          {{ agent.FirstName }} {{ agent.LaftName }}
        </h6>
        <h6 v-if="agent.company" class="text-left text-uppercase">
          {{ agent.company }}
        </h6>
        <h6 v-if="agent.phone" class="text-left">
          <a :href="agentPhone" target="_blank"
            ><span class="glyphicon glyphicon-earphone"></span>&nbsp;{{
              agent.phone
            }}</a
          >
        </h6>
        <h6 v-if="agent.email" class="text-left">
          <a :href="agentEmail" target="_blank"
            ><span class="glyphicon glyphicon-envelope"></span>&nbsp;{{
              agent.email
            }}</a
          >
        </h6>
        <h6 v-if="agent.license" class="text-left small">
          License: {{ agent.license }}
        </h6>

        <p class="d-print-none">
          <CButton
            type="link"
            v-if="agent.primary_domain"
            size=""
            v-bind:href="`http://${agent.primary_domain}`"
            target="_blank"
          >
            <font-awesome-icon icon="globe-americas" />
          </CButton>

          <CButton
            type="link"
            size=""
            v-if="agent.facebook"
            v-bind:href="`http://www.facebook.com/${agent.facebook}`"
            target="_blank"
          >
            <CIcon :height="30" :content="$options.facebookIcon" />
          </CButton>

          <CButton
            type="link"
            size=""
            v-if="agent.twitter"
            v-bind:href="`http://www.twitter.com/${agent.twitter}`"
            target="_blank"
          >
            <CIcon :height="30" :content="$options.twitterIcon" />
          </CButton>
          <CButton
            type="link"
            size=""
            v-if="agent.linkedin"
            v-bind:href="`http://www.linkedin.com/in/${agent.linkedin}`"
            target="_blank"
          >
            <CIcon :height="30" :content="$options.cibLinkedinInIcon" />
          </CButton>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { cibFacebookF, cibTwitter, cibLinkedinIn } from '@coreui/icons'

export default {
  name: "AgentInfo",
  props: {
    agent: Object,
  },
  facebookIcon: cibFacebookF,
  twitterIcon: cibTwitter,
  cibLinkedinInIcon: cibLinkedinIn,
  computed: {
    agentPhone: function () {
      return "tel:" + this.agent.phone.replace(/\D/g, "");
    },
    agentEmail: function () {
      return (
        "mailto:" +
        this.agent.email +
        "?subject=Response+from+your+Home+Val+Widget"
      );
    },
  },
};
</script>

<style scoped>
.agent-info-container {
  color: #333;
}

.agent-name {
  font-weight: 500;
  font-size: 1.125rem;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}
.flex-container-0 {
  flex-direction: row;
}
.box {
  display: flex;
  flex-direction: column;
}
.p-l-2 {
  padding-left: 2em;
}
</style>